import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/pages/Home'
import store from "../front-store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/pages/Login')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "Register" */ '../views/pages/Register')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "ForgotPassword" */ '../views/pages/ForgotPassword')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "ForgotPassword" */ '../views/pages/ResetPassword')
  },
  {
    path: '/change-plan',
    name: 'ChangePlan',
    component: () => import(/* webpackChunkName: "ForgotPassword" */ '../views/pages/ChangePlan')
  },
  {
    path: '/plan',
    name: 'Plan',
    component: () => import(/* webpackChunkName: "ForgotPassword" */ '../views/pages/Plan')
  },
  {
    path: '/plan-v2',
    name: 'HerePlan',
    component: () => import(/* webpackChunkName: "ForgotPassword" */ '../views/pages/HerePlan')
  },
  {
    path: '/re-plan',
    name: 'HereRePlan',
    component: () => import(/* webpackChunkName: "ForgotPassword" */ '../views/pages/HerePlan')
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    component: () => import(/* webpackChunkName: "Vehicles" */ '../views/pages/Vehicles'),

  },
  {
    path: '/vehicles/:id',
    name: 'EditVehicle',
    component: () => import(/* webpackChunkName: "Profile" */ '../views/pages/AddVehicle')
  },
  {
    path: '/add-vehicle',
    name: 'AddVehicle',
    component: () => import(/* webpackChunkName: "Vehicles" */ '../views/pages/AddVehicle')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "Account" */ '../views/pages/Account'),
    children: [
      {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "Profile" */ '../components/Profile'),
      },
      {
        path: 'change_password',
        name: 'Change password',
        component: () => import(/* webpackChunkName: "ChangePassword" */ '../components/ChangePassword'),
      },
      {
        path: 'billing',
        name: 'Billing',
        component: () => import(/* webpackChunkName: "Billing" */ '../components/Billing'),
      },
      {
        path: 'addons',
        name: 'Addons',
        component: () => import(/* webpackChunkName: "Addons" */ '../components/Addons'),
      },
      {
        path: 'usage',
        name: 'Usage',
        component: () => import(/* webpackChunkName: "Addons" */ '../components/Usage'),
      },
      {
        path: 'routing',
        name: 'Routing',
        component: () => import(/* webpackChunkName: "Addons" */ '../components/Routing'),
      }
    ],
  },
  {
    path: '/register-form',
    name: 'RegisterForm',
    component: () => import(/* webpackChunkName: "RegisterForm" */ '../views/pages/RegisterForm'),
    props: true
  },
  {
    path: '/drivers',
    name: 'Drivers',
    component: () => import(/* webpackChunkName: "Drivers" */ '../views/pages/Drivers'),
  },
  {
    path: '/add-or-edit-driver/:id',
    name: 'AddEditDriver',
    component: () => import(/* webpackChunkName: "AddEditDriver" */ '../views/pages/AddEditDriver'),
  },
  {
    path: '/routes',
    name: 'Routes',
    component: () => import(/* webpackChunkName: "RegisterForm" */ '../views/pages/Routes'),
    props: true
  },
  {
    path: '/routes/:id',
    name: 'Route',
    component: () => import(/* webpackChunkName: "RegisterForm" */ '../views/pages/Route'),
    props: true
  },
  {
    path: '/routes-v2/:id',
    name: 'HereApiRoute',
    component: () => import(/* webpackChunkName: "RegisterForm" */ '../views/pages/RouteV2'),
    props: true
  },
  {
    path: '/trailers',
    name: 'Trailers',
    component: () => import(/* webpackChunkName: "Trailers" */ '../views/pages/Trailers'),
  },
  {
    path: '/add-or-edit-trailer/:id',
    name: 'AddEditTrailer',
    component: () => import(/* webpackChunkName: "AddEditTrailer" */ '../views/pages/AddEditTrailer'),
  },
  {
    path: '/locations',
    name: 'Locations',
    component: () => import(/* webpackChunkName: "Locations" */ '../views/pages/Locations'),
  },
  {
    path: '/routes/:id/print',
    name: 'RoutePrint',
    component: () => import(/* webpackChunkName: "RegisterForm" */ '../views/pages/RoutePrint'),
    props: true
  },
  {
    path: '/routes/:id/print-v2',
    name: 'RoutePrintV2',
    component: () => import(/* webpackChunkName: "RegisterForm" */ '../views/pages/RoutePrintV2'),
    props: true
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import(/* webpackChunkName: "RegisterForm" */ '../views/pages/Invoices'),
    props: true
  },
  {
    path: '/addon-purchase-success',
    name: 'SuccessPage',
    component: () => import(/* webpackChunkName: "RegisterForm" */ '../views/pages/SuccessPage'),
    props: true
  },
  {
    path: '/admin'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  const user = JSON.parse(localStorage.getItem('user')) || null;

  if (!['Login', 'Register', 'RegisterForm', 'ForgotPassword', 'ResetPassword'].includes(to.name) && !store.getters.isAuthenticated) next({ name: 'Login' })
  else next()

  if (
      !['ChangePlan', 'Profile'].includes(to.name) &&
      store.getters.isAuthenticated &&
      (!user.user_subscription_id || user?.user_subscription?.status === 'cancelled')
  ) {
      next({name: 'ChangePlan'})
  }
  else next()

  if (to.name == 'Login' && store.getters.isAuthenticated) next({ name: 'Home' })
  else next()

  if (user && (user.role == 1 || user.role == 4)) {
    if (to.path !== '/admin') {
      next({path: '/admin'})
      window.location.reload()
    }
    else next()
  }
  else next()
});

export default router
